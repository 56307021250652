import { createContext, useContext } from "react";
import useWebSocket                  from "react-use-websocket";

import {
	getDeviceType,
	getAppVersion,
	getDeviceId,
	getUserData
} from "_common/utils/user";
import { configs } from "_config/index";

const WebSocketContext = createContext ();

export const useRTCWebSocketContext = () => useContext ( WebSocketContext );

const RTCWebSocketProvider = ( { children, isWebview } ) => {
	const userAuth   = getUserData ();
	const deviceType = getDeviceType ();
	const appVersion = getAppVersion ();
	const deviceId   = getDeviceId ();

	const { sendJsonMessage } = useWebSocket (
		configs.ROOTER_RTC,
		{
			queryParams: {
				token: `Bearer ${ userAuth?.accessToken }`,
				appVersion,
				deviceId,
				deviceType
			},
			onError         : () => { },
			onOpen          : () => { },
			onClose         : () => { },
			onMessage       : () => { },
			shouldReconnect : () => true
		}, !isWebview );

	const sendMessage = ( { event, data } ) => {
		if ( isWebview )
			return;

		sendJsonMessage ( {
			event,
			data: {
				authToken: `Bearer ${ userAuth?.accessToken }`,
				...data
			}
		} );
	};

	return (
		<WebSocketContext.Provider value = { { sendMessage } }>
			{children}
		</WebSocketContext.Provider>
	);
};

export default RTCWebSocketProvider;
